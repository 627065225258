import { render, staticRenderFns } from "./PoliticaPrivacidadePlanejaSaude.vue?vue&type=template&id=8afbd1ae&scoped=true&"
import script from "./PoliticaPrivacidadePlanejaSaude.vue?vue&type=script&lang=js&"
export * from "./PoliticaPrivacidadePlanejaSaude.vue?vue&type=script&lang=js&"
import style0 from "./PoliticaPrivacidadePlanejaSaude.vue?vue&type=style&index=0&id=8afbd1ae&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8afbd1ae",
  null
  
)

export default component.exports